<template>
    <div>
        <header>
            <div class="logo">
                <a href="/"><h1>MailPal</h1></a>
            </div>
        </header>
        <main>
            <div class="content">
                <div v-if="status === 'success'">
                    <h1>Address deactivated 🎉</h1>
                    <a href="/"><button class="continue-button">View all addresses</button></a>
                </div>
                <div v-if="status === 'error'">
                    <h1>Address not found</h1>
                    <a href="/"><button class="continue-button">View all addresses</button></a>
                </div>
                <div v-if="status === 'loading'">
                    <h1>Deactivating address</h1>
                    <LoadingIcon />
                </div>
            </div>
        </main>
        <footer>
            <div class="footer">
                <a href="/">Home</a>
                <a href="/settings">Settings</a>
                <a href="/help">Help</a>
                <a href="/privacy">Privacy</a>
            </div>
        </footer>
    </div>
</template>

<script>
    import { Action } from '@/store/address/types'
    import LoadingIcon from '@/components/LoadingIcon'

    export default {
        name: 'Deactivate',
        data() {
            return {
                address: undefined,
                status: 'loading'
            }
        },
        components: {
            LoadingIcon
        },
        mounted () {
            this.$store.dispatch(Action.DEACTIVATE_ADDRESS, this.address)
                .then(() => {
                    this.status = 'success'
                })
                .catch(() => {
                    this.status = 'error'
                })
        },
        created(){
            const address = this.$route.query.address

            if (!address) {
                window.location = '/'
                return
            }

            this.address = address
        }
    }
</script>

<style>
    html,
    body {
        margin: 0;
        font-family: Inter UI,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
        color: var(--font);
        background: var(--background);
    }

    .content{
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    a{
        text-decoration: none;
        color: var(--font);
    }

    button{
        -moz-appearance: none;
        appearance: none;
        border: 0;
        padding: 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        cursor: pointer;
    }

    button:active{
        transform: scale(0.95);
    }


    .continue-button{
        background: var(--primary);
        color: var(--font-dark);
        font-size: 13px;
        border-bottom: 2px solid var(--primary-dark) !important;
    }

    .logo{
        position: absolute;
        top: 10px;
        left: 30px;
    }

    .logo h1{
        cursor: pointer;
    }

    footer{
        position: absolute;
        bottom: 0;
        width: 100%;
        background: var(--background-sidemenu);
        padding: 10px 0;
    }

    .footer{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .footer a{
        margin: 1rem;
    }

</style>